/**
 * Title: Flat icons set 2 Flaticon Pack
 * Author: Silviu Runceanu
 * Source: http://www.flaticon.com/packs/flat-icons-set-2
 * License:  CC BY 3.0 (http://creativecommons.org/licenses/by/3.0/)
 */

@font-face {
	font-family: "fl-flat-icons-set-2";
	src: url("../fonts/fl-flat-icons-set-2.eot");
	src: url("../fonts/fl-flat-icons-set-2.eot#iefix") format("embedded-opentype"),
	url("../fonts/fl-flat-icons-set-2.woff") format("woff"),
	url("../fonts/fl-flat-icons-set-2.ttf") format("truetype"),
	url("../fonts/fl-flat-icons-set-2.svg") format("svg");
	font-weight: normal;
	font-style: normal;
}

.fl-flat-icons-set-2-ico,
[class^="fl-flat-icons-set-2-"]:before, [class*=" fl-flat-icons-set-2-"]:before,
[class^="fl-flat-icons-set-2-"]:after, [class*=" fl-flat-icons-set-2-"]:after {   
	font-family: 'fl-flat-icons-set-2';
    font-size: inherit;
	font-weight: 400;
	font-style: normal;
}

.fl-flat-icons-set-2-baby141:before {
	content: "\e000";
}
.fl-flat-icons-set-2-battery134:before {
	content: "\e001";
}
.fl-flat-icons-set-2-blank26:before {
	content: "\e002";
}
.fl-flat-icons-set-2-business164:before {
	content: "\e003";
}
.fl-flat-icons-set-2-chat48:before {
	content: "\e004";
}
.fl-flat-icons-set-2-checkmark12:before {
	content: "\e005";
}
.fl-flat-icons-set-2-connector1:before {
	content: "\e006";
}
.fl-flat-icons-set-2-cross72:before {
	content: "\e007";
}
.fl-flat-icons-set-2-empty34:before {
	content: "\e008";
}
.fl-flat-icons-set-2-file61:before {
	content: "\e009";
}
.fl-flat-icons-set-2-glass45:before {
	content: "\e00a";
}
.fl-flat-icons-set-2-link50:before {
	content: "\e00b";
}
.fl-flat-icons-set-2-low32:before {
	content: "\e00c";
}
.fl-flat-icons-set-2-music210:before {
	content: "\e00d";
}
.fl-flat-icons-set-2-mute29:before {
	content: "\e00e";
}
.fl-flat-icons-set-2-open197:before {
	content: "\e00f";
}
.fl-flat-icons-set-2-pencil81:before {
	content: "\e010";
}
.fl-flat-icons-set-2-pie53:before {
	content: "\e011";
}
.fl-flat-icons-set-2-placeholder17:before {
	content: "\e012";
}
.fl-flat-icons-set-2-power97:before {
	content: "\e013";
}
.fl-flat-icons-set-2-print32:before {
	content: "\e014";
}
.fl-flat-icons-set-2-right135:before {
	content: "\e015";
}
.fl-flat-icons-set-2-shopping191:before {
	content: "\e016";
}
.fl-flat-icons-set-2-speaker74:before {
	content: "\e017";
}
.fl-flat-icons-set-2-star154:before {
	content: "\e018";
}
.fl-flat-icons-set-2-two300:before {
	content: "\e019";
}
.fl-flat-icons-set-2-user141:before {
	content: "\e01a";
}
.fl-flat-icons-set-2-visibility:before {
	content: "\e01b";
}
