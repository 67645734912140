/*
* Fonts
*/
@import "fonts/font-awesome";
@import "fonts/mdi"; 
@import "fonts/fl-bigmug-line";
@import "fonts/fl-36-slim-icons";
@import "fonts/linearicons";
@import "fonts/fl-budicons-free";
@import "fonts/material-icons";
@import "fonts/fl-flat-icons-set-2";
@import "fonts/mercury";
