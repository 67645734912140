/**
 * Title: 36 Slim Icons Flaticon Pack
 * Author: Fermam Aziz
 * Source: http://www.flaticon.com/packs/36-slim-icons
 * License:  CC BY 3.0 (http://creativecommons.org/licenses/by/3.0/)
 */

@font-face {
	font-family: "fl-36-slim-icons";
	src: url("../fonts/36-slim-icons.eot");
	src: url("../fonts/36-slim-icons.eot#iefix") format("embedded-opentype"),
	url("../fonts/36-slim-icons.woff") format("woff"),
	url("../fonts/36-slim-icons.ttf") format("truetype"),
	url("../fonts/36-slim-icons.svg") format("svg");
	font-weight: normal;
	font-style: normal;
}

.fl-36-slim-icons-ico,
[class^="fl-36-slim-icons-"]:before, [class*=" fl-36-slim-icons-"]:before,
[class^="fl-36-slim-icons-"]:after, [class*=" fl-36-slim-icons-"]:after {
	font-family: "fl-36-slim-icons";
	font-size: inherit;
	font-weight: 400;
	font-style: normal;
}

.fl-36-slim-icons-bird73:before {
	content: "\e000";
}
.fl-36-slim-icons-bookmark38:before {
	content: "\e001";
}
.fl-36-slim-icons-calculator63:before {
	content: "\e002";
}
.fl-36-slim-icons-calendar148:before {
	content: "\e003";
}
.fl-36-slim-icons-circles21:before {
	content: "\e004";
}
.fl-36-slim-icons-circular242:before {
	content: "\e005";
}
.fl-36-slim-icons-clock91:before {
	content: "\e006";
}
.fl-36-slim-icons-cloud288:before {
	content: "\e007";
}
.fl-36-slim-icons-cogwheel25:before {
	content: "\e008";
}
.fl-36-slim-icons-download155:before {
	content: "\e009";
}
.fl-36-slim-icons-email90:before {
	content: "\e00a";
}
.fl-36-slim-icons-file77:before {
	content: "\e00b";
}
.fl-36-slim-icons-global32:before {
	content: "\e00c";
}
.fl-36-slim-icons-happy49:before {
	content: "\e00d";
}
.fl-36-slim-icons-house130:before {
	content: "\e00e";
}
.fl-36-slim-icons-image77:before {
	content: "\e00f";
}
.fl-36-slim-icons-laptop111:before {
	content: "\e010";
}
.fl-36-slim-icons-like67:before {
	content: "\e011";
}
.fl-36-slim-icons-like68:before {
	content: "\e012";
}
.fl-36-slim-icons-monitor79:before {
	content: "\e013";
}
.fl-36-slim-icons-musical110:before {
	content: "\e014";
}
.fl-36-slim-icons-pencil98:before {
	content: "\e015";
}
.fl-36-slim-icons-phone358:before {
	content: "\e016";
}
.fl-36-slim-icons-photo193:before {
	content: "\e017";
}
.fl-36-slim-icons-placeholder27:before {
	content: "\e018";
}
.fl-36-slim-icons-play90:before {
	content: "\e019";
}
.fl-36-slim-icons-rss49:before {
	content: "\e01a";
}
.fl-36-slim-icons-search89:before {
	content: "\e01b";
}
.fl-36-slim-icons-share31:before {
	content: "\e01c";
}
.fl-36-slim-icons-shopping223:before {
	content: "\e01d";
}
.fl-36-slim-icons-speech105:before {
	content: "\e01e";
}
.fl-36-slim-icons-star165:before {
	content: "\e01f";
}
.fl-36-slim-icons-tag65:before {
	content: "\e020";
}
.fl-36-slim-icons-user152:before {
	content: "\e021";
}
.fl-36-slim-icons-video166:before {
	content: "\e022";
}
.fl-36-slim-icons-wifi76:before {
	content: "\e023";
}
